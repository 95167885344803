export const getItemSlug = function(type, name) {
  let newName = name;
  newName = newName.toLowerCase();
  newName = newName.replace(/ /g, "-");
  newName = newName.replace(/'/g, "");

  return `/items/${type}/${newName}`;
};

export const StatNames = {
  critChance: "Crit Chance",
  HP: "HP",
  manaMp: "Mana MP",
  magicRegen: "Magic Regen",
  attackSpeed: "Attack Speed",
  moveSpeed: "Move Speed",
  MPRegen: "MP Regen",
};
